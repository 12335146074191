import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * ClickableArea works like an <a> element, but should be used for very specific cases, where the
 * <a> element would cause difficulties to accessibility.
 *
 * This element should be used in cases where links with the same `href` and the same accessible
 * text are duplicated for some reason. One such example is a product card. We have a product title
 * wrapped in a link to the product page, and next to it we have a product image with the same
 * target. This causes an accessibility problem because we shouldn't have any of the following:
 * - a link with no accessible title (`img alt` would be the accessible title here)
 * - a decorative image having non-empty alt
 * - two links with the same title (and the same target)
 * - non-clickable image
 *
 * ClickableArea is a workaround for this situation. It is not focusable and has a role of
 * `presentation`. It is also not a real HTML link (&lt;a&gt;). This means it will be completely
 * invisible to the assistive technology while offering a possibility for mouse/touchscreen users to
 * have a bigger are they can click on.
 *
 * #### When not to use this component
 * It should never be used when the slotted content contains information that's not repeated nearby.
 * In other words it should never be used if this would cause to hide information and/or
 * functionality from users with disabilities. This means it should also never be used when there
 * are no other links to the clickable-area url in a close proximity of the element.
 */
class ClickableArea extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-url'
    ];
  }

  readonly dataset!: {
    /**
     * Absolute or relative URL to the target page
     *
     * If left empty, the redirect will be disabled
     */
    url: string;
  };

  private onClickedBound = this.onClicked.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    const clickable = this.shadowRoot.querySelector('.clickable-wrapper');
    clickable.addEventListener('click', this.onClickedBound);
  }

  public disconnectedCallback() {
    const clickable = this.shadowRoot.querySelector('.clickable-wrapper');
    clickable?.removeEventListener('click', this.onClickedBound);
  }

  private onClicked(_event: Event) {
    if (this.dataset.url) {
      window.location.href = this.dataset.url;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'clickable-area': ClickableArea;
  }
}

// This module is included in a section that is loaded multiple times, so we need to handle
// idempotency by checking whether the element is defined.

if (!customElements.get('clickable-area')) {
  customElements.define('clickable-area', ClickableArea);
}
